import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取收藏应用列表
export function get_collection_list() {
  return request({
    url: `/application-store-service/collection/applications`,
    method: "get",
  });
}
// 收藏应用
export function add_collection(applicationId) {
  return request({
    url: `/application-store-service/collection/applications/${applicationId}`,
    method: "post",
  });
}
// 取消收藏应用
export function cancel_collection(applicationId) {
  return request({
    url: `/application-store-service/collection/applications/${applicationId}`,
    method: "delete",
  });
}

// 获取已获取应用列表
export function get_obtained_list() {
  return request({
    url: `/application-store-service/applicationConsume/applications`,
    method: "get",
  });
}
// 应用列表排序
export function get_sorted_list(data) {
  return request({
    url: `/application-store-service/applicationConsume/applications/sort`,
    method: "put",
    data,
  });
}
// 获取应用
export function get_app_by_id(applicationId) {
  return request({
    url: `/application-store-service/applicationConsume/applications/${applicationId}`,
    method: "post",
  });
}
// 移除应用
export function delete_app_by_id(applicationId) {
  return request({
    url: `/application-store-service/applicationConsume/applications/${applicationId}`,
    method: "delete",
  });
}

// 获取第三方应用列表
export function get_third_apps_list() {
  return request({
    url: `/application-store-service/applicationStore/applications`,
    method: "get",
  });
}
// 获取第三方应用详情
export function get_third_app_detail_by_id(applicationId) {
  return request({
    url: `/application-store-service/applicationStore/applications/${applicationId}`,
    method: "get",
  });
}
// 创建第三方应用
export function create_third_app(data) {
  return request({
    url: `/application-store-service/msOperation/applicationStore/applications`,
    method: "post",
    data,
  });
}
// 删除第三方应用
export function delete_third_app_by_id(applicationId) {
  return request({
    url: `/application-store-service/msOperation/applicationStore/applications/${applicationId}`,
    method: "delete",
  });
}
