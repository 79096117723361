<template>
  <div>
    <el-button
      size="mini"
      type="info"
      class="setting-btn"
      @click="go_to_setting"
      ><i class="el-icon-setting"></i>&nbsp;&nbsp;{{
        $t("personal.thirdPartyManagement.settings.setBtn")
      }}</el-button
    >
    <el-tabs v-model="activeName">
      <el-tab-pane
        :label="$t('personal.thirdPartyManagement.tab1')"
        name="myApps"
      >
        <div style="overflow: auto; height: 80vh">
          <el-row type="flex" justify="space-around" class="box">
            <el-card
              v-for="item in obtained_list"
              :key="item.applicationId"
              class="box-card"
              @click.native="go_to_detail(item)"
            >
              <el-row type="flex">
                <el-col class="box-card-avatar">
                  <el-avatar
                    style="background: transparent; width: 100%"
                    shape="square"
                    :size="50"
                    :src="item.avatar"
                  ></el-avatar>
                </el-col>
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <el-col class="box-card-name"> {{ item.name }} </el-col>
                </el-tooltip>
                <el-col class="box-card-btn"> </el-col>
              </el-row>
            </el-card>
          </el-row>

          <el-row type="flex" class="middle">
            <el-col :span="7" class="middle-head middle-head-title">
              {{ $t("personal.thirdPartyManagement.title") }}
            </el-col>
            <el-col :span="7">
              <el-input
                v-model="search_third_app_name"
                class="middle-search-input"
                size="small"
                :placeholder="$t('personal.thirdPartyManagement.placeholder')"
                prefix-icon="el-icon-search"
              >
              </el-input>
            </el-col>
            <el-col :span="3" class="middle-head"> </el-col>
            <el-col :span="3" class="middle-head"> </el-col>
            <el-col :span="3" class="middle-head"
              >{{ third_app_list.length
              }}{{ $t("personal.thirdPartyManagement.info") }}</el-col
            >
          </el-row>

          <el-row type="flex" justify="flex-start" class="main">
            <el-card
              v-for="item in third_app_list"
              :key="item.applicationId"
              class="main-card"
              @click.native="go_to_detail(item)"
              style="width: calc((100% - 100px) / 3);"
            >
              <el-row type="flex">
                <el-col class="main-card-avatar" :span="7">
                  <el-avatar
                    style="background: transparent; width: 100%"
                    shape="square"
                    :size="40"
                    :src="item.avatar"
                  >
                  </el-avatar>
                </el-col>
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <el-col class="main-card-name"> {{ item.name }} </el-col>
                </el-tooltip>
                <el-col class="main-card-btn">
                  <i
                    v-if="!isCollected(item.applicationId)"
                    style="font-size: 40px"
                    class="el-icon-star-off"
                    @click="add_collection($event, item)"
                  ></i>
                  <i
                    v-if="isCollected(item.applicationId)"
                    style="font-size: 45px; color: rgba(255, 195, 0, 1)"
                    class="el-icon-star-on"
                    @click="cancel_collection($event, item)"
                  ></i>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col class="main-card-des-content">
                  {{ $t("personal.thirdPartyManagement.detail.intro") }}:
                  <p>{{ descriptionLanguage(item.description) }}</p>
                </el-col>
              </el-row>
            </el-card>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('personal.thirdPartyManagement.tab2')"
        name="favorites"
      >
        <div style="overflow: auto; height: 80vh">
          <el-row type="flex" class="middle-favorite">
            <el-col :span="6">
              <el-input
                v-model="search_collect_name"
                class="middle-search-input"
                size="small"
                :placeholder="$t('personal.thirdPartyManagement.placeholder')"
                prefix-icon="el-icon-search"
              >
              </el-input>
            </el-col>
            <el-col :offset="6" :span="4" class="middle-head"> </el-col>
            <el-col :span="3" class="middle-head"> </el-col>
            <el-col :span="3" class="middle-head"
              >{{ collection_list.length
              }}{{ $t("personal.thirdPartyManagement.info") }}</el-col
            >
          </el-row>

          <el-row type="flex" justify="flex-start" class="main">
            <el-card
              v-for="item in collection_list"
              :key="item.applicationId"
              class="main-card"
              @click.native="go_to_detail(item)"
            >
              <el-row type="flex">
                <el-col class="main-card-avatar">
                  <el-avatar
                    style="background: transparent; width: 100%"
                    shape="square"
                    :size="40"
                    :src="item.avatar"
                  ></el-avatar>
                </el-col>
                <el-col class="main-card-name"> {{ item.name }} </el-col>
                <el-col class="main-card-btn">
                  <i
                    v-if="!isCollected(item.applicationId)"
                    style="font-size: 40px"
                    class="el-icon-star-off"
                    @click="add_collection($event, item)"
                  ></i>
                  <i
                    v-if="isCollected(item.applicationId)"
                    style="font-size: 45px; color: rgba(255, 195, 0, 1)"
                    class="el-icon-star-on"
                    @click="cancel_collection($event, item)"
                  ></i>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col class="main-card-des-content">
                  {{ $t("personal.thirdPartyManagement.detail.intro") }}:
                  <p>{{ descriptionLanguage(item.description) }}</p>
                </el-col>
              </el-row>
            </el-card>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  get_collection_list,
  add_collection,
  cancel_collection,
  get_third_apps_list,
} from "@/network/third-party/index";
export default {
  data() {
    return {
      activeName: "myApps",
      collection_list: [], // 已收藏的apps
      third_app_list: [], // 第三方应用市场的apps
      origin_third_app_list: [], // 原始值
      origin_collection_list: [], // 原始值
      timer: null,
      search_third_app_name: "",
      search_collect_name: "",
    };
  },
  computed: {
    obtained_list() {
      return this.$store.getters.project_third_apps;
    },
  },
  watch: {
    // 在第三方应用市场搜索 防抖
    search_third_app_name(newVal) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!newVal) {
        this.third_app_list = [...this.origin_third_app_list];
        return;
      }
      newVal = newVal.toLowerCase(); // 不区分大小写
      this.timer = setTimeout(() => {
        const result = [];
        for (let item of this.origin_third_app_list) {
          if (item.name.toLowerCase().indexOf(newVal) > -1) {
            result.push(item);
          }
        }
        this.third_app_list = result;
      }, 100);
    },
    // 在收藏夹中搜索 防抖
    search_collect_name(newVal) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!newVal) {
        this.collection_list = [...this.origin_collection_list];
        return;
      }
      newVal = newVal.toLowerCase(); // 不区分大小写
      this.timer = setTimeout(() => {
        const result = [];
        for (let item of this.origin_collection_list) {
          if (item.name.toLowerCase().indexOf(newVal) > -1) {
            result.push(item);
          }
        }
        this.collection_list = result;
      }, 100);
    },
  },
  mounted() {
    this.get_collection_list();
    this.get_third_apps_list();
  },
  methods: {
    // 描述区分语言类型
    descriptionLanguage(des) {
      if (des) {
        return des[localStorage.getItem("lang") || "zh"];
      }
    },
    // 获取收藏应用列表
    get_collection_list() {
      get_collection_list()
        .then((res) => {
          this.collection_list = [...res];
          this.origin_collection_list = [...res];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 判断是否被收藏
    isCollected(id) {
      for (let item of this.collection_list) {
        if (item.applicationId === id) return true;
      }
      return false;
    },
    // 收藏应用
    add_collection(event, item) {
      event.stopPropagation();
      const { applicationId } = item;
      this.collection_list.push(item);
      add_collection(applicationId)
        .then((res) => {})
        .catch(() => this.collection_list.pop());
    },
    // 取消收藏应用
    cancel_collection(event, item) {
      event.stopPropagation();
      const { applicationId } = item;
      let index;
      for (let i = 0; i < this.collection_list.length; i++) {
        if (this.collection_list[i].applicationId === applicationId) {
          index = i;
          this.collection_list.splice(index, 1);
          cancel_collection(applicationId)
            .then((res) => {})
            .catch(() => this.collection_list.splice(index, 1, item));
          return;
        }
      }
    },
    // 获取第三方应用列表
    get_third_apps_list() {
      get_third_apps_list()
        .then((res) => {
          this.third_app_list = [...res];
          this.origin_third_app_list = [...res];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 跳转设置页面
    go_to_setting() {
      this.$router.push({
        path: "/profile/third_party/setting",
      });
    },
    // 跳转配置页面
    go_to_config(event, item) {
      this.$router.push({
        name: "third_party_detail",
        params: { applicationId: item.applicationId, name: item.name },
      });
      event.stopPropagation();
    },
    // 跳转详情页面
    go_to_detail(item) {
      this.$router.push({
        name: "third_party_detail",
        params: { applicationId: item.applicationId, name: item.name },
      });
    },
    // 排序
    sort_card() {},
  },
};
</script>

<style lang="scss" scoped>
img {
  // width: 100% !important;
  display: none !important;
}
.setting-btn {
  position: absolute;
  left: 90%;
  z-index: 1;
}
.dropdown {
  cursor: pointer;
}
.box {
  flex-flow: wrap;
  justify-content: flex-start !important;
  margin: 10px;
  align-items: center;
  &-card {
    margin: 10px;
    width: 300px;
    height: 125px;
    border-radius: 18px;
    cursor: pointer;
    &-avator,
    &-name,
    &-btn {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }
  }
}
.middle {
  margin-top: 30px;
  &-favorite {
    margin-top: 10px;
    margin-left: 10px;
  }
  &-search-input {
    width: 314px;
  }
  &-head {
    color: rgba(112, 112, 112, 1);
    font-size: 18px;
    &-title {
      color: black;
      font-size: 18px;
      text-align: left;
      padding-left: 30px;
    }
  }
}

.main {
  margin-top: 10px;
  // margin: 10px;

  flex-flow: wrap;
  &-card {
    width: 400px;
    height: 170px;
    margin: 10px;
    border-radius: 18px;
    cursor: pointer;
    &-name {
      font-size: 18px;
      overflow: hidden;
      white-space: nowrap;
    }
    &-des-content {
      color: rgba(112, 112, 112, 1);
      margin-top: 18px;
      font-size: 16px;
      text-align: start;
      display: flex;
      p {
        padding: 0;
        margin: 0;
        width: 85%;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
